/* eslint-disable camelcase, no-console */
import {
	type UserProfile as Profile,
	User,
	UserManager,
	type UserManagerSettings,
	WebStorageStateStore,
	InMemoryWebStorage,
} from "oidc-client-ts";
import join from "lodash/fp/join";

import { mapUserProfile } from "./userProfile";
import { config } from "../../config";
import type { OAuthConfig } from "..";
import type { AccessToken } from "../tokenHandling/tokenSlice";
import type { UserProfile } from "./loginSlice";

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
	userManager.signinSilent().catch((error: Error) => {
		if (error.message === "login_required") {
			oauthConfig.onSessionExpired();
		} else {
			setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
		}
	});
};

export type SessionRenewedResult = {
	accessToken: AccessToken;
	idToken: Profile;
	profile: UserProfile;
	locale: string;
};

export const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
	accessToken: result.access_token,
	idToken: result.profile,
	locale: result.profile?.locale ?? "en-GB",
	profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
	const redirectUri = config.login.redirectUri;
	const silentRedirectUri = config.login.silentRedirectUri;

	const settings: UserManagerSettings = {
		authority: `${config.login.authority}`,
		client_id: `${config.login.clientId}`,
		loadUserInfo: false,
		redirect_uri: `${redirectUri}`,
		response_type: "code",
		scope: join(" ", config.login.oauthScope),
		silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
		includeIdTokenInSilentRenew: false,
		automaticSilentRenew: true,
		monitorSession: true,
		staleStateAgeInSeconds: 600,
		userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
		filterProtocolClaims: false,
	};

	return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
	userManager.events.addUserLoaded((user) => {
		oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
	});

	userManager.events.addUserUnloaded(() => {
		oauthConfig.onSessionExpired();
	});

	userManager.events.addSilentRenewError(() => {
		retrySigninSilent(oauthConfig, userManager);
	});

	userManager.events.addUserSignedOut(() => {
		oauthConfig.onSessionExpired();
	});

	return userManager;
};

export const configureMockUserManager = (oauthConfig: OAuthConfig): UserManager => {
	const signinSilent = () => {
		const userSettings = {
			// manual token
			access_token:
				"eyJraWQiOiJjMDNmNDMwMy05OTk4LTRkYmItYjgxNC03OGI0MmIwNmE0NDkiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJwcm9kLXJpby11c2Vyczo4OTljNjRiMS1jOTBiLTQxMjgtYjI5My04MTdiZmU4OWExNzciLCJhenAiOiI1ZTAwYjlhZS0yZWQ4LTExZWYtYjE2ZS04N2ZkODFmNzIwOGIiLCJzY29wZSI6ImFzc2V0LWFkbWluaXN0cmF0aW9uLnJlYWQgbWFwLnJlYWQgcGhvbmUgb3BlbmlkIGRyaXZlcnMucmVhZCBwcm9maWxlIHRhZ3MucmVhZCBlbWFpbCIsImlzcyI6Imh0dHBzOi8vYXV0aC5pYW0ucmlvLmNsb3VkIiwiZXhwIjoxNzI0OTU5NDI3LCJpYXQiOjE3MjQ5NTU4MjcsImp0aSI6ImZiNTcxNGUxLTc2M2EtNDM4My04NDFiLTEwZjVlZmQ3ZTVjMiIsImNsaWVudF9pZCI6IjVlMDBiOWFlLTJlZDgtMTFlZi1iMTZlLTg3ZmQ4MWY3MjA4YiIsImFjY291bnQiOiIwMDYyMzQwYS1iOTQ5LTQxMTktODBmNi1lNWIyMzhlMTgzMTMiLCJ0ZW5hbnQiOiJyaW8tYnJhemlsLnByb2QifQ.3Sk9NhC33q1-BpYCluhbUpV5_1VJ0fcDylWRtJbTFI6GNAgVBc0dQu0DQHnq8o-0D16TUsY_yz22HRUUOvsmmg5slSwn5iO35NQcnI0mHWWqVwAvJzed0x95fOo7Twq6fnCi43eOqloDbk8iMQhjrUuCWMixyQftIfMddRZuo-5xo27_iiTi4-M-S0b8aewh7RgMNVJ8T2IAMc_U-GXwUziaTEZtM3A8MeeAHCyCeI-BsM-8CM0oteV_a7ttelR8h5J3YOgcqQ8Pn1s2-6tS1aRuOUNnjxIC1mj88lEn7mLrSkZyr6SNWPdB-OE-S3YpZdsHp7FxAhaRmP89H0PWxo2p8ucMqhb0JCGZTYfUYJjavfDW7e2GFXI9r0l1_0Od2OnMN4ukKIx3SfEeW8cOd8sIq70Qq9BC0C0Dj__c6wgBgP0W0Bp-03-xeWyT9ziM5BYs0ky6vB12pZNv8St_9P3nZX8iQ5yER9xMqHOHDodkHHXKdBOw_N6Th84qVH_waPGQK-IvkgK0odG1WWcJXaNBhOGZha-UC1gqO3_lNd5tNT2gNs2mZpVHYmTjvoKEYghhARbWhPPULX_DFwrtr3UeDG1XidKD2GuQViptxgTndxrZampUImX6dEU9UbmmBMzFveA4pS5Dx-DfAOQkLu5fkD5jxBRYfDDj3H6nE8s",
			profile: {
				iss: "Issuer Identifier",
				aud: "Audience(s): client_id",
				exp: 10,
				iat: 5,
				account: "local", // mock possible account ids here
				azp: "test-client",
				email: "test@example.com",
				family_name: "Client",
				given_name: "Test",
				name: "Test Client",
				sub: "prod-rio-users:mock-user",
				locale: config.login.mockLocale,
				tenant: config.login.mockTenant,
			},
			id_token: "id_token",
			session_state: "session_state",
			refresh_token: "refresh_token",
			token_type: "token_type",
			scope: "scope",
			expires_at: 100000,
			state: "state",
		};

		const user = new User(userSettings);
		oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
		return Promise.resolve(user);
	};

	const clearStaleState = () => {
		console.info("[configuration/login/oidc-session] Stale state cleared");
		return Promise.resolve();
	};

	return { signinSilent, clearStaleState } as UserManager;
};
