import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeaders } from "../configuration/tokenHandling/prepareHeaders";
import { dynamicBaseQuery } from "../constants/services";

export const truckApi = createApi({
	reducerPath: "truckApi",
	baseQuery: dynamicBaseQuery,
	endpoints: (builder) => ({
		getAssets: builder.query<any, void>({
			query: () => ({
				url: "/asset",
			}),
		}),

		getTags: builder.query<any, void>({
			query: () => ({
				url: "https://api.tags.rio.cloud/tags",
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data.items;
				},
			}),
		}),
		getSummary: builder.mutation<any, void>({
			query: (requestPayload) => ({
				url: "/summary",
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
		sendEmailFaultCodes: builder.mutation<any, any>({
			query: (requestPayload) => ({
				url: "/faultCodes/email",
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
		getFaultCodes: builder.mutation<any, any>({
			query: (requestPayload) => ({
				url: "/faultCodes",
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
		getFaultCodesChart: builder.mutation<any, any>({
			query: (requestPayload) => ({
				url: "/faultCodes/topFaults",
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
	}),
});

export const {
	useGetAssetsQuery,
	useSendEmailFaultCodesMutation,
	useGetTagsQuery,
	useGetSummaryMutation,
	useGetFaultCodesMutation,
	useGetFaultCodesChartMutation,
} = truckApi;
