import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../configuration/setup/store";
import { truckApi } from "../../services/truckApi";
import { AlertsPageState, DialogState, VehicleStatus } from "./alertsPageSlideTypes";

const initialState: AlertsPageState = {
	expandedRows: [],
	reloadPage: false,
	tableAlertsData: {
		isLoading: false,
		data: [],
	},
	openDialogNotifications: {
		open: false,
		row: {},
	},
};

const alertsPageSlice = createSlice({
	name: "alertsPage",
	initialState,
	reducers: {
		triggerReloadPage: (state) => {
			state.reloadPage = !state.reloadPage;
		},
		setExpandedRows: (state, action: PayloadAction<string[]>) => {
			state.expandedRows = action.payload;
		},
		setTableAlertsData: (state, action: PayloadAction<VehicleStatus[]>) => {
			state.tableAlertsData.data = action.payload;
		},
		setTableAlertsDataLoading: (state, action: PayloadAction<boolean>) => {
			state.tableAlertsData.isLoading = action.payload;
		},
		toggleRowExpansion: (state, action: PayloadAction<string>) => {
			const rowId = action.payload;
			if (state.expandedRows.includes(rowId)) {
				state.expandedRows = state.expandedRows.filter((id) => id !== rowId);
			} else {
				state.expandedRows.push(rowId);
			}
		},
		setOpenDialogNotifications: (state, action: PayloadAction<DialogState>) => {
			state.openDialogNotifications = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(truckApi.endpoints.getFaultCodes.matchPending, (state) => {
			state.tableAlertsData.isLoading = true;
		});
		builder.addMatcher(truckApi.endpoints.getFaultCodes.matchFulfilled, (state, { payload }) => {
			state.tableAlertsData.isLoading = false;
			state.tableAlertsData.data = payload;
		});
		builder.addMatcher(truckApi.endpoints.getFaultCodes.matchRejected, (state) => {
			state.tableAlertsData.isLoading = false;
		});
	},
});

export const {
	setExpandedRows,
	toggleRowExpansion,
	setTableAlertsDataLoading,
	setOpenDialogNotifications,
	triggerReloadPage,
	setTableAlertsData,
} = alertsPageSlice.actions;
export const getDialogAlert = (state: RootState) => state.pageAlerts.openDialogNotifications;
export const getPageAlertReload = (state: RootState) => state.pageAlerts.reloadPage;
export const getPageAlertsData = (state: RootState) => state.pageAlerts;
export default alertsPageSlice.reducer;
